<!-- © 2021 Tfarraj -->
<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="ma-auto">
        <v-card outlined :loading="loading">
          <div class="px-8 pt-6 pb-12">
            <v-card-title class="text-center"
              ><img class="responsive-img" src="/logo.svg" :alt="siteName"
            /></v-card-title>
            <v-card-subtitle class="mb-5">{{
              $t('signin.signin')
            }}</v-card-subtitle>
            <v-card-text>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <form
                  @submit.prevent="handleSubmit(signin)"
                  @reset.prevent="reset"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="email"
                      v-bind:error-messages="
                        errors != '' ? $t('signin.' + errors) : ''
                      "
                      v-bind:label="$t('signin.email')"
                      outlined
                      autofocus
                      tabindex="1"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <p class="ma-0 text-right">
                      <v-btn
                        text
                        small
                        class="pl-0 text-none"
                        color="indigo"
                        to="reset-password"
                        >{{ $t('signin.forgotPassword') }}</v-btn
                      >
                    </p>
                    <v-text-field
                      v-model="password"
                      type="password"
                      v-bind:error-messages="
                        errors != '' ? $t('signin.' + errors) : ''
                      "
                      v-bind:label="$t('signin.password')"
                      outlined
                      tabindex="2"
                    ></v-text-field>
                  </ValidationProvider>
                  <div class="mt-6 d-flex justify-space-between">
                    <v-btn
                      text
                      small
                      class="pl-0 text-capitalize"
                      color="indigo"
                      router
                      to="signup"
                      >{{ $t('signin.createAccount') }}</v-btn
                    >
                    <v-btn
                      type="submit"
                      class="primary"
                      :loading="loading"
                      depressed
                      tabindex="3"
                      >{{ $t('signin.signin') }}</v-btn
                    >
                  </div>
                </form>
              </ValidationObserver>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Config from '@/config';
import LocalizationMixin from '@/mixins/LocalizationMixin.ts';

export default {
  name: 'SignIn',
  mixins: [LocalizationMixin],
  data: () => ({
    siteName: Config.siteName,
    email: '',
    password: '',
    loading: false,
  }),
  methods: {
    async signin() {
      if (this.loading) return;
      this.loading = true;
      try {
        const data = await this.$store.dispatch('signIn', {
          email: this.email,
          password: this.password,
        });
        if (!data) return;
        const user = await this.$store.dispatch('retrieveUser');
        if (!user) return;
        const channel = await this.$store.dispatch('retrieveChannel');
        if (user.status === 'active') {
          await this.$store.dispatch('retrieveSubscribedChannels');
        }
        if (this.$route.query.from) {
          this.$router.push(this.$route.query.from);
        } else {
          this.$router.push({ name: 'Home' });
        }
        this.setLocale();
      } catch (e) {
        console.error(e);
        this.$refs.form.setErrors({
          Email: ['wrongEmail'],
          Password: ['wrongPassword'],
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
